import UrlIcon from 'students/views/shared/components/UrlIcon/UrlIcon';
import {
  customMediaQuery,
  SNoStyleButton,
  SRichEditorTextWrapper
} from 'students/views/shared/styled';
import styled from 'styled-components';

import FinishTaskButton from '../../../components/FinishTaskButton';

export const STextTaskWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-block-end: -1rem;
  width: 100%;

  ${customMediaQuery('desktop')} {
    max-width: 921px;
    align-self: center;
    margin-block-end: 0;
  }
`;

export const STitle = styled.h1<{ light: boolean }>`
  color: ${({ light }) =>
    light ? 'var(--lingu-font-light-color)' : 'var(--lingu-font-dark-color)'};
  font-size: 1.5rem;
  font-weight: bold;
  margin-block-end: 1.625rem;
  text-align: start;

  ${customMediaQuery('desktop')} {
    text-align: center;
    margin-block-end: 3.75rem;
  }
`;

export const SStickyHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column-reverse;
  width: 100%;
  background-color: var(--color-white);
  padding: 1rem 2rem 1rem 1rem;
  border-radius: 20px;

  & > button {
    margin-block-end: 8px;
    align-self: center;
  }

  ${customMediaQuery('desktop')} {
    padding: 1rem 3rem;
    flex-direction: row;

    & > button {
      margin-inline-end: 16px;
    }
  }
`;

export const SContentWrapper = styled.div`
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0 -1rem -1rem;
  background: var(--color-white);
  padding-bottom: 2rem;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  ${customMediaQuery('tablet')} {
    margin: 0;
    padding-bottom: 0;
    border-radius: 20px;
  }
`;

export const SFinishTaskButton = styled(FinishTaskButton)`
  margin: 0;
`;

export const SButtonBlock = styled.div`
  order: 3;
  background: var(--color-white);
  align-self: center;
  margin-inline-start: -2rem;
  margin-inline-end: -2rem;
  padding: 0 2rem 2rem;
  width: calc(100% + 2rem);

  ${customMediaQuery('tablet')} {
    width: 340px;
    padding: 40px 0;
    background: transparent;
  }
`;

export const STextContentBlock = styled(SRichEditorTextWrapper)`
  order: 2;
  padding: 1rem;
  width: 100%;
  overflow-x: auto;
  letter-spacing: -0.02em;

  &.no-overflow {
    overflow: initial;
  }

  ${customMediaQuery('tablet')} {
    padding: 2.75rem 2.25rem;
    min-height: max-content;
    letter-spacing: -0.01em;
  }
`;

export const SBackdrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(45, 45, 58, 0.9);
  z-index: -1;
  opacity: 0;
  transition: opacity 0.4s;

  &.visible {
    opacity: 1;
    z-index: 1;
  }
`;

export const SLangButton = styled(SNoStyleButton)<{
  $original: boolean;
  $hasError: boolean;
}>`
  padding: 11px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 0.8125rem;
  line-height: 0.875rem;
  border-radius: 12px;
  width: 100%;

  ${({ $original }) =>
    $original ? 'border: 1px solid #A7AAB6' : 'border: 1px solid transparent'};

  ${({ $hasError }) =>
    $hasError ? 'background-color: #F41B1B1A' : 'background-color: #E6E6F0'};

  &:hover,
  &:active,
  &:disabled {
    ${({ $hasError }) =>
      $hasError ? 'background-color: #F41B1B1A' : 'background-color: #DFDFEC'};

    ${({ $original }) =>
      $original ? 'border: 1px solid #A7AAB6' : 'border: 1px solid transparent'};
  }

  &:focus {
    outline: 3px solid var(--color-blue-btn-focus-outline-color);
    outline-offset: 2px;

    ${({ $original }) =>
      $original ? 'border: 1px solid #A7AAB6' : 'border: 1px solid transparent'};

    ${({ $hasError }) =>
      $hasError ? 'background-color: #F41B1B1A' : 'background-color: #DFDFEC'};
  }
`;

export const SLangButtonText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;

  ${customMediaQuery('tablet')} {
    flex-direction: row;
  }
`;

export const SFlag = styled(UrlIcon)`
  margin-inline-end: 0.5rem;
`;

export const STranslatedWithBlock = styled.div`
  padding-left: 8px;
  margin-left: 8px;
  display: flex;
  align-items: center;

  ${customMediaQuery('tablet')} {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }

  img {
    width: 120px;
  }
`;

export const SErrorBlock = styled.div`
  display: flex;
  align-items: center;
  color: #0b1420;

  div {
    display: none;
  }

  ${customMediaQuery('tablet')} {
    padding-left: 8px;
    margin-left: 8px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);

    div {
      display: block;
    }
  }
`;

export const STranlationErrorText = styled.p`
  text-align: center;
  font-size: 0.875rem;
  opacity: 80%;
`;
