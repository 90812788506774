import { ILearnTextTask } from '@adeptlms/lingu-students-react-shared';
import { FC } from 'react';

import LearnTaskTemplate from '../common/LearnTaskTemplate';
import { ILearnTaskProps } from '../types';

const LearnTextTask: FC<ILearnTaskProps<ILearnTextTask>> = ({
  task,
  onFinish,
  lightFont,
  isCompleting
}) => {
  const {
    title,
    type,
    imageURL,
    mobileImageURL,
    coverImg,
    imageAlt,
    giphyImage,
    items: [{ content, audioURL }]
  } = task;

  return (
    <LearnTaskTemplate
      onFinish={onFinish}
      lightFont={lightFont}
      isCompleting={isCompleting}
      task={{
        title,
        audioURL,
        imageURL,
        mobileImageURL,
        coverImg,
        giphyImage,
        content,
        imageAlt,
        type
      }}
    />
  );
};

export default LearnTextTask;
