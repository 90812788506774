import { ITranslatableTextTask } from '@adeptlms/lingu-students-react-shared';
import { FC } from 'react';

import LearnTaskTemplate from '../common/LearnTaskTemplate';
import { ILearnTaskProps } from '../types';

const LearnTranslatableTextTask: FC<ILearnTaskProps<ITranslatableTextTask>> = ({
  task,
  onFinish,
  lightFont,
  isCompleting
}) => {
  const {
    title,
    type,
    imageURL,
    imageAlt,
    mobileImageURL,
    coverImg,
    giphyImage,
    items: [
      {
        audioURL,
        content,
        translation,
        sourceLanguage: sourceLang,
        targetLanguage,
        fallbackTargetLanguageCode
      }
    ]
  } = task;

  return (
    <LearnTaskTemplate
      onFinish={onFinish}
      lightFont={lightFont}
      isCompleting={isCompleting}
      task={{
        title,
        audioURL,
        imageURL,
        imageAlt,
        mobileImageURL,
        coverImg,
        giphyImage,
        content,
        translation,
        sourceLanguage: sourceLang,
        targetLanguage,
        fallbackTargetLanguageCode,
        type
      }}
    />
  );
};

export default LearnTranslatableTextTask;
