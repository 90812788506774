import {
  lessonSelectors,
  lessonTaskActions,
  lessonTaskSelectors,
  tGiphyImage
} from '@adeptlms/lingu-students-react-shared';
import {
  ILanguage,
  tLearnTaskType
} from '@adeptlms/lingu-students-react-shared/lib.d.ts/data/models';
import cn from 'classnames';
import { locales, t } from 'i18n';
import languageFlags from 'i18n/config/localeFlags';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTypedDispatch, useTypedSelector } from 'students/stores/_utils';
import useZoomableImage from 'students/views/shared/bundles/zoomableImage/useZoomableImage';
import { useHeartBeatMediaControl } from 'students/views/shared/components/HeartBeat';
import ImageContent from 'students/views/shared/components/ImageContent';
import { AudioPlayer } from 'students/views/shared/components/MediaPlayer';
import { IAudioPlayerRef } from 'students/views/shared/components/MediaPlayer/AudioPlayer/AudioPlayer';
import ScrollToBottomButton, {
  tScrollToBottomButtonRef
} from 'students/views/shared/components/ScrollToBottomButton';
import TranslationTextTooltip from 'students/views/shared/components/TranslationTextTooltip';
import { usePageVisibility } from 'students/views/shared/hooks';
import { StickyHeader } from '../../../components';
import exclamationMarkIcon from './assets/exclamation_mark_icon.svg';
import translationBadge from './assets/translatedByGoogle.png';
import {
  SBackdrop,
  SButtonBlock,
  SContentWrapper,
  SErrorBlock,
  SFinishTaskButton,
  SFlag,
  SLangButton,
  SLangButtonText,
  SStickyHeaderWrapper,
  STextContentBlock,
  STextTaskWrapper,
  STitle,
  STranlationErrorText,
  STranslatedWithBlock
} from './styled';

interface ILearnTaskTemplate {
  onFinish: () => void;
  lightFont: boolean;
  isCompleting: boolean;
  task: {
    title: string;
    audioURL?: string | null;
    imageURL: string | null;
    imageAlt: string | null;
    mobileImageURL: string | null;
    coverImg: boolean | null;
    giphyImage: tGiphyImage | null;
    content: string;
    translation?: string | null;
    sourceLanguage?: Pick<ILanguage, 'code' | 'name'> | null;
    targetLanguage?: ILanguage | null;
    fallbackTargetLanguageCode?: string | null;
    type?: tLearnTaskType['type'];
  };
}

const LearnTaskTemplate: FC<ILearnTaskTemplate> = ({
  onFinish,
  lightFont,
  isCompleting,
  task
}) => {
  const {
    title,
    imageURL,
    imageAlt,
    mobileImageURL,
    coverImg,
    giphyImage,
    audioURL,
    content,
    translation,
    sourceLanguage,
    targetLanguage,
    fallbackTargetLanguageCode,
    type
  } = task;

  const currentLesson = useTypedSelector(lessonSelectors.selectLesson);
  const currentLessonTask = useTypedSelector(lessonTaskSelectors.selectLessonTask);
  console.log('TAKS', task, { currentLesson, currentLessonTask });

  const dispatch = useTypedDispatch();
  const [showOriginal, setShowOriginal] = useState(true);
  const finishTaskButtonRef = useRef<HTMLButtonElement>(null);
  const scrollButtonRef = useRef<tScrollToBottomButtonRef>(null);
  const { onStartPlaying, onStopPlaying } = useHeartBeatMediaControl();
  const audioPlayerRef = useRef<IAudioPlayerRef>(null);
  const isTranslationTask = Boolean(type === 'Tasks::TranslatableText');
  const fallbackLocale = locales.find(({ lKey }) => lKey === fallbackTargetLanguageCode);
  const targetLang = targetLanguage || {
    code: fallbackTargetLanguageCode,
    name: t(fallbackLocale?.labelKey || '')
  };
  const lang = showOriginal ? targetLang : sourceLanguage;
  const hasImageContent = !!imageURL || giphyImage;

  const { contentRef, backdropVisible, setElementRefAndInitialize } = useZoomableImage(
    {}
  );

  const createTranslationMarkup = () => {
    return {
      __html: showOriginal ? content : translation ?? ''
    };
  };

  const renderRegularMarkup = useCallback(() => {
    if (currentLesson?.languageCode === 'nb' || currentLesson?.languageCode === 'no') {
      return (
        <TranslationTextTooltip>
          <div
            dir="ltr"
            ref={setElementRefAndInitialize}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </TranslationTextTooltip>
      );
    }

    return (
      <div
        ref={setElementRefAndInitialize}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    );
  }, [currentLesson, content, setElementRefAndInitialize]);

  useEffect(() => {
    scrollButtonRef?.current?.update();
  }, [showOriginal]);

  const onPageVisibilityChange = useCallback((isHidden: boolean) => {
    isHidden && audioPlayerRef.current?.handlePause();
  }, []);

  usePageVisibility(onPageVisibilityChange);

  const reloadTask = () => {
    if (currentLesson && currentLessonTask) {
      dispatch(
        lessonTaskActions.fetchLessonTask({
          taskId: currentLessonTask.id,
          lessonId: currentLesson.id
        })
      );
    }
  };

  const handleLanguageToggle = () => {
    if (!showOriginal && !translation) {
      reloadTask();
    } else {
      setShowOriginal((showOriginal) => !showOriginal);
    }
  };

  function getTranslateButtonLabelText() {
    if (showOriginal) {
      return lang?.name
        ? t('frontend.lesson_task.tasks.learn.translatable_text.translate_into', {
            languageName: lang?.name
          })
        : t(
            'frontend.lesson_task.tasks.learn.translatable_text.translate_into_your_lang'
          );
    }

    if (!translation) {
      return (
        <span style={{ color: '#F41B1B' }}>
          {t('frontend.lesson_task.tasks.learn.translatable_text.translation_failed')}
        </span>
      );
    }

    return t('frontend.lesson_task.tasks.learn.translatable_text.show_original');
  }

  const renderStickyHeader = () =>
    audioURL || isTranslationTask ? (
      <StickyHeader>
        <SStickyHeaderWrapper>
          {isTranslationTask && lang && targetLang.code !== sourceLanguage?.code ? (
            <SLangButton
              type="button"
              onClick={handleLanguageToggle}
              $original={!showOriginal}
              $hasError={!showOriginal && !translation}
            >
              {showOriginal ? (
                <SFlag
                  url={(lang.code && languageFlags.get(lang.code)?.flag) || ''}
                  height="18px"
                  width="24px"
                />
              ) : null}

              {!showOriginal && translation ? (
                <SFlag
                  url={(lang.code && languageFlags.get(lang.code)?.flag) || ''}
                  height="18px"
                  width="24px"
                />
              ) : null}

              {!showOriginal && !translation ? (
                <SFlag url={exclamationMarkIcon} height="20px" width="20px" />
              ) : null}

              <SLangButtonText>
                {getTranslateButtonLabelText()}

                {!showOriginal && translation ? (
                  <STranslatedWithBlock>
                    <img src={translationBadge} alt="Google Translate" />
                  </STranslatedWithBlock>
                ) : null}

                {!showOriginal && !translation ? (
                  <SErrorBlock>
                    <SFlag
                      url={(lang.code && languageFlags.get(lang.code)?.flag) || ''}
                      height="18px"
                      width="24px"
                    />

                    {t(
                      'frontend.lesson_task.tasks.learn.translatable_text.retry_translation'
                    )}
                  </SErrorBlock>
                ) : null}
              </SLangButtonText>
            </SLangButton>
          ) : null}

          {audioURL ? (
            <AudioPlayer
              ref={audioPlayerRef}
              url={audioURL}
              onPlay={onStartPlaying}
              onPause={onStopPlaying}
              onEnded={onStopPlaying}
              size="small"
            />
          ) : null}
        </SStickyHeaderWrapper>
      </StickyHeader>
    ) : null;

  const renderMediaBlock = () => {
    if (hasImageContent || !!audioURL) {
      return (
        <>
          {hasImageContent ? (
            <ImageContent
              image={imageURL}
              smallImage={mobileImageURL}
              cover={coverImg}
              giphyImage={giphyImage}
              isZoomable
              alt={imageAlt || ''}
            />
          ) : null}

          {renderStickyHeader()}
        </>
      );
    }

    return renderStickyHeader();
  };

  const renderContentBlock = () => {
    if (isTranslationTask) {
      return (
        <>
          <STextContentBlock
            ref={contentRef}
            dir={showOriginal ? 'ltr' : 'auto'}
            lang={
              !showOriginal
                ? `${targetLang.code}-x-mtfrom-${sourceLanguage?.code ?? ''}`
                : undefined
            }
          >
            {!showOriginal && !translation ? (
              <STranlationErrorText>
                {t(
                  'frontend.lesson_task.tasks.learn.translatable_text.error_happened_when_translating'
                )}
              </STranlationErrorText>
            ) : null}

            <div
              ref={setElementRefAndInitialize}
              dangerouslySetInnerHTML={createTranslationMarkup()}
            />
          </STextContentBlock>

          <SBackdrop className={cn({ visible: backdropVisible })} />
        </>
      );
    }

    return (
      <STextContentBlock ref={contentRef}>
        {renderRegularMarkup()}

        <SBackdrop className={cn({ visible: backdropVisible })} />
      </STextContentBlock>
    );
  };

  return (
    <STextTaskWrapper>
      <STitle light={lightFont}>{title}</STitle>

      <SContentWrapper>
        {renderMediaBlock()}

        {renderContentBlock()}

        <ScrollToBottomButton ref={scrollButtonRef} targetRef={finishTaskButtonRef} />
      </SContentWrapper>

      <SButtonBlock>
        <SFinishTaskButton
          onClick={onFinish}
          isCompleting={isCompleting}
          ref={finishTaskButtonRef}
        />
      </SButtonBlock>
    </STextTaskWrapper>
  );
};

export default LearnTaskTemplate;
